<template>
    <KCourse loader-class="MBcont" course-id="4" title="Съемный костюм XIX в." :items="items">
        <div class="MBtextcont">
            <p>На этом уроке мы закончим работу с платьем. Готовы увидеть свою девочку в наряде? Я очень хочу увидеть ваши работы ))</p>
            <h3>7.1 Шьем подол платья</h3>
        </div>

        <VideoView video-id="39a3d6c1af0446a0af4e5081a320da82"/>

        <div class="MBtextcont">
            <h3>7.2 Собираем платье</h3>
        </div>

        <VideoView video-id="9253486b4ae84b62b00c60cbda452b07"/>

        <div class="MBtextcont">
            <h3>7.3 Декорируем платье</h3>
        </div>

        <VideoView video-id="79b40f8deb014f4b82ce68e11e3b8cac"/>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Подготовка", url:"/mycourses/costume/1"},
                { title: "Снимаем выкройку", url:"/mycourses/costume/2"},
                { title: "Моделируем", url:"/mycourses/costume/3"},
                { title: "Ткань. Раскрой", url:"/mycourses/costume/4"},
                { title: "Нижнее белье", url:"/mycourses/costume/5"},
                { title: "Шьем полочку", url:"/mycourses/costume/6"},
                { title: "Дошиваем платье", url:"/mycourses/costume/7"},
                { title: "Красное платье", url:"/mycourses/costume/8"},
                { title: "Делаем обувь", url:"/mycourses/costume/9"},
                { title: "Ручные швы", url:"/mycourses/costume/10"},
                { title: "Машинные швы", url:"/mycourses/costume/11"},
                { title: "Утюжка", url:"/mycourses/costume/12"},
            ],
        }
        }
    }
</script>